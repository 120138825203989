
@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .hide-scrollbar {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }
  /* body.light  {
    --text-color: #333;
    --bg-color: #000;
    --border-color: #ccc;
  } */
  
  body.dark {
    --text-color: #ccc;
    --bg-color: #000;
    --border-color: #444;
  }
  
  body.high-contrast {
    --text-color: #fff;
    --bg-color: #000;
    --border-color: #fff;
  }
  
  body {
    color: var(--text-color);
    background-color: var(--bg-color);
    border-color: var(--border-color);
    font-family: 'MediumFont'
  }
 
