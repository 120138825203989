@font-face {
  font-family: 'MediumFont';
  src: url('./Manrope-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./Manrope-Medium.woff2') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'arabic';
  src: url('./NotoSansArabic-VariableFont_wdth\,wght.ttf');
  font-style: normal;
}