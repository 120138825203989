.container{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  margin: 10px;
}
.container .card{
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: .5s ease;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border-radius: 10px;
}
.container .card h2{
  cursor: pointer;
}
.darken-img {
  filter: brightness(50%);
}
.container .card:nth-child(1){
  background-color: rgb(224, 243, 255);
  padding: 10px;  
}
.container .card:nth-child(2){
  background-color: rgb(250, 205, 236);
  padding: 10px;
}
.container .card:nth-child(3){
  background-color: rgb(219, 250, 195);
  padding: 10px;
}
.container .card:nth-child(4){
  grid-column: 4 / 6;
  grid-row: 1/5;
}
.container .card:nth-child(5){
  grid-column: 6 / 6;
  grid-row: 1/5;
}
.container .card:nth-child(6){
  grid-column:  1/3;
  grid-row: 2/5;
}
.container .card:nth-child(7){
  grid-row: 2/5;
}
.container .card:nth-child(8){
grid-column:  1/3;
}
.container .card:nth-child(9){
  grid-column:  3/6;
  }
/* Responsive */
@media (max-width: 900px){
  .container{
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 0.1fr 0.2fr 0.2fr 0.2fr 0.3fr 0.3fr 0.5fr 0.3fr 0.4fr 0.3fr 0.3fr 0.4fr 0.3fr 0.3fr;
  }
  .container .card{
      grid-row: unset !important;
      grid-column: unset !important;
  }
}
.dark-mode {
  background-color: #000;
  color: #e4d6d6;
}
.light-mode {
  color: #4b4848;
}
.high-contrast {
  background-color: #F5F5F5;
  color: #1A1A1A;
}
.responsive-input {
  width: 100%;
  padding: 0.5rem;
}
.responsive-input {
  width: 100%;
  padding: 0.5rem; 
}
@media only screen and (max-width: 768px) {
  .responsive-input {
    padding: 0.3rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .responsive-input {
    padding: 0.4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .responsive-input {
    padding: 0.5rem;
  }
}
.gm-style .gm-style-iw {
  border: none !important; /* Remove border */
  overflow: visible !important; /* Fixes clipping issues */
}
.gm-style-iw-d {
  overflow: hidden !important; /* Ensures no scrollbars appear */
  box-shadow: none !important; /* Remove any shadow if present */
}
.gm-ui-hover-effect {
  background-color: white !important; /* Change background to white */
  color: white !important; /* Change X color to black, if needed */
}
/* Change the SVG color inside the button */
.text-green-500 {
  color: green;
}
.text-blue-500 {
  color: blue;
}
.text-purple-500 {
  color: purple;
}
.text-red-500 {
  color: red;
}
.custom-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.installment-plan {
  padding: 20px;
}

.installment-plan form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.installment-plan form div {
  margin-bottom: 15px;
}

.installment-plan form label {
  display: block;
  margin-bottom: 5px;
}

.installment-plan form input {
  width: 100%;
  padding: 8px;
}

.installment-plan form button {
  padding: 10px 15px;
}

.installment-plan ul {
  list-style-type: none;
  padding: 0;
}

.installment-plan li {
  margin-bottom: 10px;
}

.overflow-y-auto{
  border-radius: 5px; 
}

.overflow-y-auto::-webkit-scrollbar {
  width: 5px;
}
.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar */
  border-radius: 20px;
}
.overflow-y-auto::-webkit-scrollbar-track {
 background-image: linear-gradient(to bottom, #2b4a91, #5147de ,#857eeb);
}
.spinner {
  border: 6px dashed rgba(28, 137, 226, 0.785);
  border-left-color: rgba(28, 137, 226, 0.785);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


/* Spinner CSS */
.spinners {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4f46e5; /* You can change the color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spins 5s linear infinite;
}

@keyframes spins {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.toogle:checked~.dot {
    transform: translateX(100%);
    /* background-color: #132b50; */
}
.tooltip {
  position: absolute; /* Make sure it’s positioned correctly */
  z-index: 9999; /* Ensures it appears above most elements */
  white-space: nowrap; /* Prevents long text from wrapping */
}

.group-hover:visible {
  display: block; /* In case visibility is an issue */
}


@media (max-width: 640px) {
  .date-filter-container {
    width: 100%;
    padding: 1rem;
  }
}

@media (min-width: 640px) {
  .date-filter-container {
    width: 300px;
  }
}

@media (min-width: 1024px) {
  .date-filter-container {
    width: 400px;
  }
}
.tooltips {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  z-index: 50;
}
